<template>
  <v-container >
    <v-layout align-center justify-center>
      
      <v-flex xs12  sm6 md5 lg4 >
        <v-card class="elevation-12">
    <!--       <v-toolbar dark color="primary">
            <v-toolbar-title align="center">Login</v-toolbar-title>
          </v-toolbar> -->
          <v-card-text class="mt-2">
           <v-img class ="mb-8" max-height= "100" alt="Vue logo" src="@/assets/logo-embarques.png" contain>        
            </v-img>

            <v-img class ="mb-8" max-height= "100" alt="logo empresa" :src="logourl" contain>        
            </v-img>
           
            <v-form>
              
                <!-- EMAIL -->
              <v-text-field
                v-model="email" 
                prepend-icon="mdi-email" 
                label="Correo" 
                required
                type="email">
              </v-text-field>

                <!-- PASSWORD -->
              <v-text-field
                v-model="password"
                prepend-icon="mdi-lock"
                :append-icon="show1 ? 'mdi-eye-check-outline' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="contraseña"
                hint="At least 8 characters"
                autocomplete="off"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>

              <v-flex xs12>
                <v-card v-if="error" color="red lighten-1" class=" px-2 py-2">
                  <div class="white--text">
                    {{ error }}
                  </div>
                </v-card>
              </v-flex>

            </v-form>
          </v-card-text>
            
              <!-- BOTONES -->
          <v-card-actions>
            <p class="ma-2">v.55</p>
            <v-spacer></v-spacer>
            <v-btn  color="primary" dark @click="ingresar2" class="elevation-5">Login</v-btn>
          </v-card-actions>
          <v-card-actions>
            <!-- <v-btn class="ma-2" to="https://kam.saiterp.com/app/embarques.apk"
              small dark color="green accent-4">Descargar App</v-btn> -->
              
              <!-- <a class="ma-2" href="https://kam.saiterp.com/app/embarques.apk">Descargar App</a> -->
              <a class="ma-2" :href="urlapk">Descargar App</a>


            <v-spacer></v-spacer>
            <v-btn  
              color="seconday" dark small 
              :to="{name: 'olvidacontra'}">Olvide mi contraseña.
            </v-btn>
          </v-card-actions>
          
          <div style="padding: 10px" align="right"> 
            <!-- <v-btn  color="orange" small text :to="{name: 'olvidacontra'}">Olvide mi contraseña.</v-btn> -->
          </div>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions , mapGetters} from 'vuex'
  export default {
    data(){
      return{
        urlapk:'',
        logourl:'',
        password: '',
        email:'',
        precioutil:'',
        snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 4000,
        text: '',
        isPaste: false,
        drawer: null,
        show1: false,
        error: '',
        color:'',
        logo: "@/assets/logo-embarques.png",
        rules: {
          required: value => !!value || 'Requerido.',
          min: v => v.length >= 8 || 'Minimo 8 caracteres',
          emailMatch: () => ('El correo y/o la contraseña no son correctos')
        },

        NombreAsig: '',
        Nomuser: '',
  
      }
    },

  
    created(){

      this.urlapk = process.env.VUE_APP_DESCARGAR
      // "https://kam.saiterp.com/app/kam.apk"
      console.log(process.env.VUE_APP_TITLE)



      this.$http.get('get.logo').then(response=>{
        // console.log("response", response)
        if (response.data.error == null){
          this.logourl = response.data.result.logourl
          // console.log("logo", this.logo)
        }
        
      }, error => {
        console.log(error)
      })
    },

    computed:{
      // ...mapGetters('carrito', ['getTC']),
      ...mapGetters('Login',['getdatosUsuario','getLogeado','getModo','getClientes','getCliente']),
    },

    
    methods:{
      ...mapActions('Login',['validarUser', 'GetInfoUser']),      
      // ...mapActions('tema',['traerTema']),
      ...mapActions('carrito', ['traerTC']),
      ...mapActions("snackbar", ["showSnack"]),
      
      descargar(){
        this.$router.push('https://kam.saiterp.com/app/embarques.apk')
      },

      ingresar2 (){

        this.error  = null

        var md5     = require('md5')
        let payload222 = { "Username": this.email, "Password": md5(this.password)}
        let usuario222 = { "Email"   : this.email, "Password": md5(this.password)}
        // console.log("payload222", payload222)

        this.validarUser(payload222).then(response => {
          console.log("Login Response",response)

           let token = response.token   
          console.log("token", token)
          // Asigno token a variable
          // var token = response.token          
         // Guardo el Token 
          this.$store.dispatch("guardarToken", token)

          // Mando a consultar la informacion del cliente
          this.GetInfoUser(usuario222).then(response => {

              // console.log("GetInfoUser.response", response)
              // this.$store.dispatch("guardarNivel", response.nivel )
              
            if(response === false){
              this.showSnack({
                text: "Successfully Saved!",
                color: "success",
                timeout: 3500,
              });

              // this.text     = "No existe el usuario o sus datos son incorrectos"
              // this.snackbar = true 
              // this.color = 'orange'
              return

            } else {
              // this.text     = "Iniciaste sesión"
              // this.snackbar = true 
              // this.color = 'success'
              // this.showSnack({
              //   text: "Successfully Saved!",
              //   color: "success",
              //   timeout: 2000,
              // });



              this.$store.dispatch("guardarNivel", 'USUARIO')
              this.$router.push({ name:'Home' }).catch(err => {console.log(err)})
              
              // if(this.getModo == 'vendedor'){
              //   this.traerTema(3)
              // }


              // // Traer el tipo de cambio
              // this.traerTC().then(() =>{

              //   // Validar si es cliente del usuario
              //   if(this.getCliente != ''){
              //     this.precioutil = this.getCliente.precioutil
              //     // Validar si es cleinte del vendedor

              //   }else if(this.getClientes != ''){
              //     this.precioutil = this.getClientes.precioutil
              //   }

              //   // Ver el tipo de moneda
              //   if(this.$store.state.tipomoneda == 'D'){
              //     this.moneda = true
              //   }else{
              //     this.moneda = false
              //   }

              //   this.$router.push({ name:'cotizaciones' }).catch(err => {console.log(err)})
              // }).catch(err => {console.log(err)})
            }

          }).catch(err => {console.log(err)})
        }).catch(err => {

          // Si la respuesta es 401 muestro mensaje
          if(err.status=== 401){
              // this.text     = "No existe el usuario o sus datos son incorrectos"
              // this.snackbar = true 
              // this.color = 'orange'
            this.showSnack({
                text: "No existe el usuario o sus datos son incorrectos!",
                color: "orange darken-2",
                timeout: 3500,
              });

          }
          
        })
      
      }
    }
  };


</script>

<style scoped>
  
  .logo{
    align-content: "center";
    text-align: center;

  }
</style>


  